<template>
  <b-card>
    <title-name />
    <notes-text />
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import TitleName from '@/views/Admin/Defines/Brands/elements/Title.vue'
import NotesText from '@/views/Admin/Defines/Brands/elements/Notes.vue'

export default {
  name: 'ItemForm',
  components: {
    BCard,
    TitleName,
    NotesText,
  },
}
</script>
